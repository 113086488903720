<template>
  <main id="oem">
    <div class="container">
      <div class="w-full tablet:w-3/4 mx-auto my-5">
        <header-h1
          :content="`OEM`"
          casing="uppercase"
          mt="0"
          mb="0"
          extraClass="text-h2" />
        <header-h2
          :content="page.acf.oem_description"
          mt="4"
          mb="10"
          extraClass="text-base" />

        <div>
          <section>
            <div class="flex flex-wrap">
              <div class="w-full">
                <ul class="list-disc">
                  <li
                    v-for="(item, itemIndex) in page.acf.oem_list"
                    :key="'oem-item-' + itemIndex"
                    class="oem-link">
                    <nuxt-link :to="localePath({ name: 'oem-oem', params: { oemNumber: item.oem_number }})">
                      {{ item.oem_number }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import wordpress from '@/service/wordpress';
import HeaderSlider from '@/components/headers/Slider';

export default {
  name: 'Oem',
  components: {
    HeaderSlider
  },
  head () {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title;
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc;

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  data () {
    return {
    }
  },
  async asyncData ({ params, app, store }) {
    const [page] = await Promise.all([
      wordpress.get(`page/oem`)
    ])

    return {
      page: page.data
    }
  }
}
</script>

<style scoped>
div {
  word-wrap: break-word;
}

.oem-link {
  text-decoration: underline;
}

.oem-link:hover {
  @apply text-red;
  text-decoration: none;
}
</style>

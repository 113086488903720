<template>
  <main id="account">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section v-if="!loggedIn" class="bar__categories">
      <div class="container">
        <div class="w-full tablet:w-9/10 px-3 mx-auto mb-5 pb-5">
          <header-h4
            :content="$t('components.account.reasonsToSignUp')"
            align="left"
            mt="0"
            mb="4" />

          <ListUl
            class="text-lg"
            :items="[
              $t('components.account.getPersonalisedPricing'),
              $t('components.account.viewOemReferencesForParts'),
              $t('components.account.accessFavouritesList')
          ]"/>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 px-4">
            <form @submit.prevent="submitLoginForm">
              <header-h4
                :content="$t('components.account.login')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div
                v-if="login_message"
                class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4"
              >
                <div
                  v-html="$t('components.account.login_message')"
                  class="block bg-orange px-4 py-2 text-white"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="`${$t('components.account.form.email')} *`"
                  for-id="form_field_login_email"
                  extra-class="block mb-2"
                />
                <input
                  id="form_field_login_email"
                  v-model="login_email"
                  :placeholder="`${$t('components.account.form.email')} *`"
                  type="email"
                  required
                  class="form_input"
                  :disabled="isActionInProgress"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="`${$t('components.account.form.password')} *`"
                  for-id="form_field_login_password"
                  extra-class="block mb-2"
                />
                <input
                  id="form_field_login_password"
                  v-model="login_password"
                  :placeholder="`${$t('components.account.form.password')} *`"
                  type="password"
                  required
                  class="form_input"
                  :disabled="isActionInProgress"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <p>
                  <small>
                    <nuxt-link
                      :to="localePath({ name: 'password-forgotten' })"
                      v-t="'components.account.password_forgotten'"
                    />
                  </small>
                </p>
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <button-primary
                  :text="$t('components.account.login')"
                  type="submit-form"
                  extra-class="block"
                  :is-disabled="isActionInProgress"
                />
              </div>
            </form>
          </div>
          <div
            class="w-full tablet:w-1/2 px-4 border-t tablet:border-l tablet:border-t-0 border-solid border-gray-medium pt-6 tablet:pt-0"
          >
            <form
              ref="registerForm"
              @submit.prevent="runRecaptchaCheck">
              <header-h4
                :content="$t('components.account.register')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div
                v-if="register_message"
                class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4"
              >
                <div
                  v-html="$t('components.account.register_message')"
                  class="block bg-orange px-4 py-2 text-white"
                />
              </div>
              <div
                v-if="register_message_success"
                class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4"
              >
                <div
                  v-html="$t('components.account.register_message_success')"
                  class="block bg-green px-4 py-2 text-white"
                />
              </div>
              <div v-if="!register_message_success">
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.company_name')} *`"
                    for-id="form_field_company_name"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="company_name"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.company_name')} *`
                    "
                    name="company_name"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.first_name')} *`"
                    for-id="form_field_first_name"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="first_name"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.first_name')} *`
                    "
                    name="first_name"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.last_name')} *`"
                    for-id="form_field_last_name"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="last_name"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.last_name')} *`
                    "
                    name="last_name"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.street')} *`"
                    for-id="form_field_street"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="street"
                    :required="true"
                    :placeholder="`${$t('components.account.form.street')} *`"
                    name="street"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.house_number')} *`"
                    for-id="form_field_house_number"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="house_number"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.house_number')} *`
                    "
                    name="house_number"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.postal_code')} *`"
                    for-id="form_field_postal_code"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="postal_code"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.postal_code')} *`
                    "
                    name="postal_code"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.city')} *`"
                    for-id="form_field_city"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="city"
                    :required="true"
                    :placeholder="`${$t('components.account.form.city')} *`"
                    name="city"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.country')} *`"
                    for-id="form_field_country"
                    extra-class="block mb-2"
                  />
                  <form-select
                    v-model="country"
                    :value="country"
                    :required="true"
                    :base-label="
                              `${$t('components.account.form.country')} *`
                            "
                    :options="getCountries()"
                    @change="countryChanged"
                    :is-disabled="isActionInProgress"
                    name="country"
                    id="country"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.phone')} *`"
                    for-id="form_field_phone"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="phone"
                    :required="true"
                    :placeholder="`${$t('components.account.form.phone')} *`"
                    type="tel"
                    name="phone"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.email')} *`"
                    for-id="form_field_email"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="email"
                    :required="true"
                    :placeholder="`${$t('components.account.form.email')} *`"
                    type="email"
                    name="email"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="`${$t('components.account.form.password')} *`"
                    for-id="form_field_password"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="password"
                    :required="true"
                    :placeholder="`${$t('components.account.form.password')} *`"
                    type="password"
                    name="password"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <form-label
                    :label="
                      `${$t('components.account.form.password_confirmation')} *`
                    "
                    for-id="form_field_password_confirmation"
                    extra-class="block mb-2"
                  />
                  <form-input
                    v-model="password_confirmation"
                    :required="true"
                    :placeholder="
                      `${$t('components.account.form.password_confirmation')} *`
                    "
                    type="password"
                    name="password_confirmation"
                    :is-disabled="isActionInProgress"
                  />
                </div>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <p class="mb-2">
                    {{ $t('components.account.form.what_describes_your_business') }} *
                  </p>
                  <select
                    v-model="businessDesc"
                    id="businessDesc"
                    name="businessDesc"
                    class="form-select"
                    :disabled="isActionInProgress"
                    required>
                    <option
                      v-for="(item, itemIndex) in businessDescOptions"
                      :key="'business-desc-option-' + itemIndex"
                      :value="item.value">
                      {{ item.text }}
                    </option>
                  </select>
                </div>
                <p
                  v-html="$t('components.account.siteProtectedByRecaptcha')"
                  class="form-group w-full tablet:w-4/5 tablet:mx-auto my-5 text-xs recaptcha-info">
                </p>

                <p
                  v-if="recaptchaFailed"
                  v-html="$t('components.account.recaptchaVerificationFailedInfo')"
                  class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-5 text-sm bg-red px-4 py-2 text-white">
                </p>
                <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                  <button-primary
                    :text="$t('components.account.register')"
                    type="submit-form"
                    extra-class="block"
                    :is-disabled="isActionInProgress"
                  />
                </div>
              </div>

              <input type="hidden" name="recaptcha_response" id="recaptchaResponse">
            </form>
          </div>
        </div>
      </div>
    </section>
    <section v-if="loggedIn" class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <h3 v-html="`${$t('components.account.your_details')}`"></h3>
            <address class="not-italic">
              <div v-if="user.company">
                <span v-html="user.company" />,
                <br>
              </div>
              <div v-else>
                <span v-html="`${user.firstname}`" />
                <span v-html="`${user.lastname}`" />,<br>
              </div>

              <span v-html="`${user.street}`" />
              <span v-html="`${user.number}`" />, <br>
              <span v-html="`${user.postal_code}`" />
              <span v-html="`${user.city}`" />,<br>
              <span v-html="`${user.country}`" />,<br>
              <span v-html="`E: ${user.email}`" /><br>
              <span
                v-if="user.phonenumber"
                v-html="`${$t('components.account.tel')} ${user.phonenumber}`"
              /><br>
            </address>
          </div>
          <div class="w-full px-4 mt-10">
            <font-awesome-icon
              v-if="selectedOrder !== null"
              @click="selectedOrder = null"
              :icon="['fas', 'chevron-left']"
              class="mr-3"
              style="display: inline; cursor: pointer" />
            <h3 v-html="`${$t('components.account.your_orders')}`"></h3>
            <table
              v-if="selectedOrder === null"
              class="table table-striped table--sm mt-3"
            >
              <thead>
                <tr>
                  <td v-t="'components.account.order_id'" class="text-left" />
                  <td
                    v-t="'components.account.number_of_products'"
                    class="text-left"
                  />
                  <td v-t="'components.account.placed_on'" class="text-left" />
                  <td v-t="'components.account.status'" class="text-left" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, i) in user.orders"
                  :key="order.id"
                >
                  <td
                    @click="selectedOrder = i"
                    v-html="order.id"
                    style="cursor: pointer"
                  />
                  <td
                    @click="selectedOrder = i"
                    v-html="order.order_rows.length"
                    style="cursor: pointer"
                  />
                  <td
                    @click="selectedOrder = i"
                    v-html="
                      new Date(Date.parse(order.created_at)).toLocaleString()
                    "
                    style="cursor: pointer"
                  />
                  <td>
                    {{ order.status }}
                    <a @click="submitPaymentForm(order.order_form)" class="button button--primary ml-10 cursor-pointer" v-if="false && order.order_form"><span>{{ $t('components.account.pay_order') }}</span></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              v-if="selectedOrder !== null"
              class="table table-striped table--sm"
            >
              <thead>
                <tr>
                  <td v-t="'components.account.order_id'" class="text-left" />
                  <td
                    v-t="'components.account.number_of_products'"
                    class="text-left"
                  />
                  <td v-t="'components.account.placed_on'" class="text-left" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-html="user.orders[selectedOrder].id" />
                  <td v-html="user.orders[selectedOrder].order_rows.length" />
                  <td
                    v-html="
                      new Date(
                        Date.parse(user.orders[selectedOrder].created_at)
                      ).toLocaleString()
                    "
                  />
                </tr>
              </tbody>
            </table>

            <div v-if="selectedOrder !== null">
              <hr style="border-color: #f5f4f9;" />
              <br>

              <table style="width: 100%;">
                <tbody>
                  <tr
                    v-for="orderRow in user.orders[selectedOrder].order_rows"
                    :key="orderRow.order_id"
                  >
                    <td>{{ orderRow.quantity }}x</td>
                    <td class="text-center" style="width: 25%">
                      <nuxt-link
                        v-if="JSON.parse(orderRow.product_content).sku != 'SHIPMENT'"
                        :to="
                          `/search?q=${
                            JSON.parse(orderRow.product_content).sku
                          }`
                        "
                      >
                        <img
                          v-if="JSON.parse(orderRow.product_content).images"
                          :src="JSON.parse(orderRow.product_content).images[0].replace('//','https://apair-backend.apairltd.com/')"
                          :alt="JSON.parse(orderRow.product_content).sku"
                          style="max-height: 50px; width: auto"
                        />
                      </nuxt-link>
                    </td>
                    <td class="text-center" style="25%">
                      <nuxt-link
                        v-if="JSON.parse(orderRow.product_content).sku != 'SHIPMENT'"
                        :to="
                          `/search?q=${
                            JSON.parse(orderRow.product_content).sku
                          }`
                        "
                      >
                        {{ JSON.parse(orderRow.product_content).sku }}
                      </nuxt-link>
                      <span v-else>{{ JSON.parse(orderRow.product_content).sku }}</span>
                    </td>
                    <td style="width: 25%">
                      {{ orderRow.price_per_piece * orderRow.quantity }}
                      {{ orderRow.currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w-full px-4 mt-5">
            <p class="text-right mb-6">
              <a
                @click="logout"
                class="button button--primary cursor-pointer"
              >
                <span>{{ $t('components.account.logout') }}</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../components/bars/Breadcrumbs';
import portal from '../service/portal';
import Countries from "@/helpers/Countries";

export default {
  name: 'Account',
  components: { BarBreadcrumbs },
  head() {
    const title = 'Account - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      script: [
        {
          hid: 'recaptcha',
          src: 'https://www.google.com/recaptcha/api.js?render=' + process.env.recaptchaPublicKey
        }
      ]
    }
  },
  data() {
    return {
      businessDesc: null,
      selectedOrder: null,
      loggedIn: this.$auth.loggedIn,
      user: this.$auth.loggedIn ? this.$auth.user : {},
      company_name: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      street: '',
      house_number: '',
      house_number_addition: '',
      postal_code: '',
      city: '',
      country: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: '',
      login_email: '',
      login_password: '',
      remember_me: false,
      register_message: false,
      register_message_success: false,
      login_message: false,
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.account'),
          url: 'account'
        }
      ],
      recaptchaFailed: false,
      businessDescOptions: [
        {
          value: 'dealer',
          text: this.$t('components.account.form.dealer')
        },
        {
          value: 'independent_engineer',
          text: this.$t('components.account.form.independent_engineer')
        },
        {
          value: 'parts_distributor',
          text: this.$t('components.account.form.parts_distributor')
        },
        {
          value: 'end_user',
          text: this.$t('components.account.form.end_user')
        },
        {
          value: 'other',
          text: this.$t('components.account.form.other')
        }
      ],
      isActionInProgress: false
    }
  },
  methods: {
    getCountries() {
      return Countries;
    },
    countryChanged(country) {
      this.country = country;
    },
    logout() {
      this.$auth.logout();
      // this.loggedIn = false

      // cart will reload on page reload
      window.location.reload(true);
    },
    submitRegisterForm(recaptchaToken) {
      this.isActionInProgress = true;
      const params = new URLSearchParams()
      params.append('company', this.company_name)
      params.append('firstname', this.first_name)
      params.append('lastname', this.last_name)
      params.append('street', this.street)
      params.append('number', this.house_number)
      params.append('postal_code', this.postal_code)
      params.append('city', this.city)
      params.append('country', this.country)
      params.append('phonenumber', this.phone)
      params.append('email', this.email)
      params.append('password', this.password)
      params.append('password_confirmation', this.password_confirmation)
      params.append('in_mailinglist', 0)
      params.append('lang', this.$i18n.locale)
      params.append('recaptcha_response', recaptchaToken)
      params.append('business_desc', this.businessDescOptions.find(item => item.value === this.businessDesc).text)

      portal
        .post('auth/signup', params, {
          'Content-Type': 'application/x-www-form-urlencoded'
        })
        .then((data) => {
          if (data.status === 201) {
            this.register_message = false
            this.register_message_success = true
            this.company_name = ''
            this.first_name = ''
            this.middle_name = ''
            this.last_name = ''
            this.street = ''
            this.house_number = ''
            this.house_number_addition = ''
            this.postal_code = ''
            this.city = ''
            this.country = ''
            this.phone = ''
            this.login_email = this.email
            this.email = ''
            this.password = ''
            this.password_confirmation = ''
            this.businessDesc = null
            this.$forceUpdate()
          } else {
            this.register_message_success = false
            this.register_message = true
            this.password_confirmation = ''
          }

          this.isActionInProgress = false;
        })
        .catch((err) => {
          if (err.status === 201) {
            this.register_message = false
            this.register_message_success = true
            this.company_name = ''
            this.first_name = ''
            this.middle_name = ''
            this.last_name = ''
            this.street = ''
            this.house_number = ''
            this.house_number_addition = ''
            this.postal_code = ''
            this.city = ''
            this.country = ''
            this.phone = ''
            this.login_email = this.email
            this.email = ''
            this.password = ''
            this.password_confirmation = ''
            this.businessDesc = null
            this.$forceUpdate()
          } else {
            this.register_message_success = false
            this.register_message = true
            this.password_confirmation = ''
          }

          this.isActionInProgress = false;
        })
    },
    submitLoginForm() {
      if (this.isActionInProgress) {
        return;
      }
      this.isActionInProgress = true;
      this.$auth
        .loginWith('local', {
          data: {
            email: this.login_email,
            password: this.login_password,
            remember_me: this.remember_me
          }
        })
        .then(() => {
          dataLayer.push({'event': 'logged_in'})
          if (this.$auth.loggedIn) {
            this.login_message = false;
            // this.loggedIn = true;
            this.login_email = '';
            this.login_password = '';
            // load user saved cart
            this.$store.dispatch('loadSavedCart');

            window.location.reload(true);
          } else {
            this.login_message = true;
          }

          this.isActionInProgress = false;
        })
        .catch((e) => {
          this.login_message = true;
          this.isActionInProgress = false;
        })
    },
    submitPaymentForm(order) {
      this.$store.dispatch('submitPaymentForm', {order: order});
    },
    runRecaptchaCheck () {
      if (this.isActionInProgress) {
        return;
      }

      this.isActionInProgress = true;
      let recaptchaField = this.$refs.registerForm.querySelector('#recaptchaResponse');

      let that = this;

      if (!window.grecaptcha) {
        this.recaptchaFailed = true;
        this.isActionInProgress = false;
        return;
      }
      grecaptcha.ready(function () {
        grecaptcha.execute(process.env.recaptchaPublicKey, {action: 'contact'})
        .then(function (token) {
          recaptchaField.value = token;
          that.submitRegisterForm(token);
        }).catch(function () {
          that.recaptchaFailed = true;
          that.isActionInProgress = false;
        });
      });
    },
    updateUserOrders (orders) {
      this.user.orders = orders;
    }
  },
  mounted() {
    if(this.loggedIn){
      let that = this;
      this.$store.dispatch('fetchUserOrders').then(function(response){
        if(response){
          let orders = response.data;
          that.updateUserOrders(orders);
        }
      });
    }
  }
}
</script>

<style lang="scss">
.grecaptcha-badge {
  display:none;
}

.recaptcha-info a {
  text-decoration: underline;

  &:hover {
    @apply text-red;
  }
}

.form-select {
  border-color: rgba(69, 69, 69, 1);
  border-style: solid;
  border-width: 1px;
  display: block;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  width: 100%;

  &:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  }
}
</style>
